
@font-face {
    font-family: "Proxima Nova";
    font-weight: 400;
    src: url("./ProximaNovaReg.otf") format("opentype");
}

body {
    font-family: Proxima Nova;
    font-size: 1.2rem;
}

.navBar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 300;
    display: -ms-flexbox;
    display: flex;
    height: 5.6rem;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 3rem;
    background-color: white;
    border-bottom: solid 1px #dce1e6;
    height: 56px;
}

.navBar .logo {
    display: inline-block;
    width: 10rem;
    height: 42px;
    background: url(./blipparLogo.svg) no-repeat 0 8px/auto 27px;
}

.blippar-share {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    margin-top: 65px;
}

.marker-image {
    width: 500px;
    height: 500px;
    max-width: 90vw;
    max-height: 90vw;
}

.download-instruction {
    margin-top: 10px;
}
